<template>
  <div>
    <el-button v-if="this.devSecondType == 136" type="primary" @click="ammeterPairDialog" style="margin-bottom:10px">附属设备</el-button>
    <el-dialog
      title="配对设备"
      :visible.sync="ammeterObj.dialogAmmeter"
      width="710px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="10" :offset="9">
          <el-select
            v-model="ammeterObj.ammeterDevices"
            placeholder="请选择"
            size="mini"
            @change="ammeterDevicesAlter"
          >
            <el-option
              v-for="item in ammeterObj.dataList"
              :key="item.jourDeviceId"
              :label="item.devNickname"
              :value="item.jourDeviceId"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button type="primary" @click="ammeterPair">配对</el-button>
      </div>
    </el-dialog>
    <el-card class="box-card">
      <el-form inline>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="累计用量:">
              <el-tag type="success">{{
                scok.electricityConsumption
              }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前电压:">
              <el-tag type="success">{{ scok.voltage }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="当前功率:">
              <el-tag type="success">{{ scok.power }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前电流:">
              <el-tag type="success">{{
                scok.electricCurrent
              }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    ammeterObj: {
      type: Object,
    },
    es: {
      type: Number | String,
    },
    id: {
      type: Number | String,
    },
    devSecondType: {
      type: Number | String,
    },
  },
  data() {
    return {
      scok: {},
    };
  },
  watch: {
    es: {
      handler: function(val) {
        console.log(val);
        if (val) {
          this.getkind();
          this.timer = setInterval(() => {
            this.getkind();
          }, 3000);
        } else {
          clearInterval(this.timer);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ammeterDevicesAlter(value) {
      console.log(value);
      this.ammeterObj.jourDeviceId = value;
    },
    ammeterPair() {
      this.$axios({
        method: 'post',
        url: `/common2/devices/attached/link`,
        params: {
          id0: this.ammeterObj.devId,
          id1: this.ammeterObj.jourDeviceId,
        },
      })
        .then((res) => {
          for (let i in this.ammeterObj.dataList) {
            if (
              this.ammeterObj.dataList[i].jourDeviceId ==
              this.ammeterObj.jourDeviceId
            ) {
            }
          }
          if (res.data.status == 200) {
            this.$message({
              message: '配对成功',
              type: 'success',
            });
          } else {
            this.$message({
              message: '配对失败',
              type: 'error',
            });
          }
          console.log(res);
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    ammeterPairDialog() {
      this.ammeterObj.dialogAmmeter = true;
      //查询配对全部设备
      this.$axios({
        method: 'get',
        url: `/common2/devices/pairableDevice/${this.id}`,
      })
        .then((res) => {
        //   for (let item of res.data.result) {
        //     item.key = item.jourDeviceId;
        //     item.label = `${item.address}-${item.devNickname}`;
        //   }
          console.log(res.data.result);
          this.ammeterObj.dataList = res.data.result;
          // //查询已配对
          // this.$axios({
          //   method: 'get',
          //   url: `/common2/jourDevicePairRecords/pairedRecords`,
          //   params: {
          //     deviceId: this.form.clickrow.jourDeviceId,
          //   },
          // })
          //   .then((res) => {
          //     this.loading1 = false;
          //     let result = res.data.result;
          //     console.log(result);
          //     this.pairingObj.pairedDevices = result;
          //     this.pairingObj.dataList.map((item) => {
          //       let back = this.pairingObj.pairedDevices.indexOf(
          //         item.jourDeviceId
          //       );
          //       if (
          //         back !== -1 &&
          //         (item.devSecondType == 92 || item.devSecondType == 89)
          //       ) {
          //         item.disabled = true;
          //       }
          //     });
          //   })
          //   .catch((err) => {
          //     this.loading1 = false;
          //     this.isError(err, this);
          //   });
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getkind() {
      this.$axios({
        method: 'get',
        // url: `/common2/devices/getBatchState`,
        url: `/common2/devices/getDevicesState`,
        params: {
          ids: this.ammeterObj.devId,
        },
      })
        .then((res) => {
          console.log(res.data.result[0]);
          this.scok = res.data.result[0];
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>
